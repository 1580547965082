.portfolio-container {
  display: grid;
  grid-template-columns: 90vw;
  grid-gap: 50px;
  justify-content: center;
}

@media (min-width: 768px) {
  .portfolio-container {
    grid-template-columns: auto auto auto;
  }
}

.project {
  position: relative;
}

.project-over {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
}

.project:hover .project-over {
  opacity: 1;
}

.over-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}